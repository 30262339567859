import React from 'react';
import SEO from "../common/SEO";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import {Link} from "react-router-dom";
import HeaderOne from '../common/header/HeaderOne';
import CounterUpFour from '../elements/counterup/CounterUpFour';
import ServiceOne from '../elements/service/ServiceOne';
import AboutFour from '../elements/about/AboutFour';
import AdvancePricingOne from '../elements/advancePricing/AdvancePricingOne';
import BrandOne from '../elements/brand/BrandOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';


const BannerData = [
    {
        image: "/images/bg/bg-image-19.jpg",
        title: "Together, we can <br/> beat the markets.",
        description: "Trade one step ahead with Birkley MarketIQ®. Coming this March. "
    }
]


const WebAgency = () => {
    return (
        <>
            <SEO title="Birkley" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />


                {/* Start Slider Area  */}
                <div className="slider-area slider-style-8 height-650">
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="container">
                                <div className="row align-items-start">
                                    <div className="col-lg-12">
                                        <div className="inner">
                                            <h1 className="title" dangerouslySetInnerHTML={{__html: data.title }}></h1>
                                            <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                            <div className="button-group mt--30">
                                                <a className="btn-default btn-large" target="_blank" href="#">Notify me <span class="opacity-50">– when released</span></a>
                                                <a className="btn-default btn-border btn-large" href="#">Contact Us</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* End Slider Area  */}


              

               
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gapTop rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Can't wait?"
                                    title = "What to expect"
                                    description = ""
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                                serviceStyle = "service__style--1 radius mt--25 rbt-border"
                                textAlign = "text-left"
                             />
                    </div>
                </div>
                {/* End Service Area  */}

            
                
              
              
                <Copyright />
            </main>
        </>
    )
}
export default WebAgency;